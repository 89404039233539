import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { generalInformationSectionTitle } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { DemandCurrentStatus } from '@eogile/agrements-common/src/demand/components/DemandCurrentStatus';
import { FdjCaseType, FdjDemand } from '../fdjDemandTypes';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import ReadOnlyField from '@eogile/agrements-common/src/form/components/ReadOnlyField';
import SelectAdapter from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { fdjAgreementTypeLabels, fdjCaseTypeLabels } from '../fdjDemandUtils';
import CheckboxAdapter from '@eogile/agrements-common/src/form/components/CheckboxAdapter';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { useFdjIntegratedDemandFormHelper } from '../hooks/useFdjIntegratedDemandFormHelper';
import { useAgreementTypeFormHelper } from '../hooks/useAgreementTypeFormHelper';
import { useIsLightDemandFormHelper } from '@eogile/agrements-common/src/demand/hooks/useIsLightDemandFormHelper';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { FormGridContainersStack } from '@eogile/agrements-common/src/components/grid/FormGridContainersStack';
import { useFdjAgencyOptionsFormHelper } from '../hooks/useFdjAgencyOptionsFormHelper';
import { FdjDemandFormTooltipContents } from './FdjDemandFormTooltipContents';
import { maxLength, minLength, onlyDigits } from '@eogile/agrements-common/src/form/helper/validators';
import { useFdjOutletCodeFormHelper } from '../hooks/useFdjOutletCodeFormHelper';
import { FdjOngoingDemandWithSameOutletCodeDialog } from './FdjOngoingDemandWithSameOutletCodeDialog';
import MainActionButton from '@eogile/agrements-common/src/components/buttons/MainActionButton';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { useIsOngoingPmuDemandFormValue } from '../hooks/useIsOngoingPmuDemandFormValue';
import { useExistingAttachmentsRemovalWarningDisplayHelper } from '@eogile/agrements-common/src/demand/components/ExistingAttachmentsRemovalWarningFieldsProvider';
import { CurrentFdjApplicationUserDetails } from '../../security/types';
import { FdjUserProfile } from '../../user/fdjUserTypes';
import { isMinistryUser } from '@eogile/agrements-common/src/user/userUtils';

type Props = {
  demand: FdjDemand | undefined;
  userDetails: CurrentFdjApplicationUserDetails;
};

function displayRootDemandCheckbox(userDetails: CurrentFdjApplicationUserDetails): boolean {
  return isMinistryUser(userDetails) || !userDetails.profiles.includes(FdjUserProfile.FDJ_AGENCY);
}

export const FdjDemandGeneralInformationSection = ({ demand, userDetails }: Props) => {
  const formMode = useFormMode();
  const isLightDemand = useIsLightDemandFormHelper();
  const { isCreationCaseType, isIntegratedDemand, requestPrefill, prefillError } = useFdjIntegratedDemandFormHelper();
  const { compatibleAgreementTypes } = useAgreementTypeFormHelper();
  const { agencyOptions, regionalDirectionOfSelectedAgency } = useFdjAgencyOptionsFormHelper(demand);
  const {
    canRequestRdi,
    requestRdi,
    pendingRdiRequest,
    existingOngoingDemand,
    checkOngoingDemandWithSameOutletCodeExists,
    closeOngoingDemandExistsDialog,
  } = useFdjOutletCodeFormHelper();
  const { value: isOngoingPmuDemand } = useIsOngoingPmuDemandFormValue();
  const { getExistingAttachmentsRemovalWarningIfNeeded } = useExistingAttachmentsRemovalWarningDisplayHelper();

  const onRootChronoNumberBlur = async () => {
    await requestPrefill();
  };

  const onOutletCodeBlur = async () => {
    if (formMode === FormMode.CREATE) {
      await checkOngoingDemandWithSameOutletCodeExists();
    }
  };

  return (
    <SectionCard title={generalInformationSectionTitle}>
      <FormGridContainersStack>
        <DemandCurrentStatus demand={demand} showDemandCompany={false} />
        <FormGridContainer>
          <FormGridItem>
            <ReadOnlyField
              label="Direction régionale"
              value={regionalDirectionOfSelectedAgency?.name}
              required
              dataTestId="regionalDirection-field"
            />
          </FormGridItem>
          <FormGridItem>
            <SelectAdapter
              name="agencyCode"
              options={agencyOptions}
              label="Secteur"
              required
              disabled={agencyOptions.length === 1}
            />
          </FormGridItem>
        </FormGridContainer>
        <FormGridContainer>
          <FormGridItem>
            <EnumSelectAdapter
              name="caseType"
              label="Type de dossier"
              options={Object.values(FdjCaseType)}
              optionName={(opt) => fdjCaseTypeLabels[opt]}
              required
              warningText={getExistingAttachmentsRemovalWarningIfNeeded('caseTypeChange')}
            />
          </FormGridItem>
          <FormGridItem>
            {displayRootDemandCheckbox(userDetails) && <CheckboxAdapter name="rootDemand" label="Demande d'enseigne" />}
          </FormGridItem>
          {isCreationCaseType && (
            <>
              <FormGridItem>
                <CheckboxAdapter name="integratedDemand.integratedDemand" label="Point de vente intégré ?" />
              </FormGridItem>
              {isIntegratedDemand && (
                <FormGridItem>
                  <TextFieldAdapter
                    name="integratedDemand.rootDemandChronoNumber"
                    label="Numéro de dossier de la demande de l’enseigne"
                    onBlur={onRootChronoNumberBlur}
                    errorText={prefillError}
                    required
                  />
                </FormGridItem>
              )}
            </>
          )}
        </FormGridContainer>
        {compatibleAgreementTypes.length > 0 && (
          <FormGridContainer>
            <FormGridItem>
              <EnumSelectAdapter
                name="agreementType"
                label="Type d'agrément"
                required
                options={compatibleAgreementTypes}
                optionName={(opt) => fdjAgreementTypeLabels[opt]}
              />
            </FormGridItem>
          </FormGridContainer>
        )}
        <FormGridContainer alignItems="center">
          <FormGridItem>
            <TextFieldAdapter
              name="outlet.code"
              label="Code détaillant"
              onBlur={onOutletCodeBlur}
              required
              validators={[onlyDigits, minLength(5), maxLength(6)]}
              infoTooltipContent={<FdjDemandFormTooltipContents.OutletCode />}
            />
          </FormGridItem>
          {canRequestRdi && (
            <FormGridItem>
              <MainActionButton
                dataTestId="rdi-button"
                label="Interroger RDI"
                action={requestRdi}
                pendingSpinner={pendingRdiRequest}
              />
            </FormGridItem>
          )}
        </FormGridContainer>
        <FormGridContainer>
          <FormGridItem>
            <CheckboxAdapter
              label="Y-a-t-il eu un avis favorable SCCJ pour ce détaillant dans les 12 derniers mois ?"
              name="receivedGrantedVerdictInPast12Months"
              infoTooltipContent={<FdjDemandFormTooltipContents.ReceivedGrantedVerdictInPast12Months />}
            />
          </FormGridItem>
          <FormGridItem>
            <CheckboxAdapter label="Demande PMU en cours" name="ongoingPmuDemand" />
          </FormGridItem>
          {(isLightDemand || isOngoingPmuDemand) && (
            <FormGridItem>
              <TextFieldAdapter name="previousChronoNumber" label="N° Chrono ancienne demande" />
            </FormGridItem>
          )}
        </FormGridContainer>
      </FormGridContainersStack>
      <FdjOngoingDemandWithSameOutletCodeDialog
        demand={existingOngoingDemand}
        onContinueDemandCreation={closeOngoingDemandExistsDialog}
      />
    </SectionCard>
  );
};
