import { FdjCaseType, FdjRootDemandView, isFdjFullRootDemandView } from '../fdjDemandTypes';
import { useFdjCaseTypeFormValue } from './useFdjCaseTypeFormValue';
import { useForm } from 'react-final-form';
import { toLightCandidateFormValues } from '../fdjDemandFormUtils';
import { toAcquirerFormValues, toCandidateFormValues } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { useIntegratedDemandFormHelper } from '@eogile/agrements-common/src/demand/hooks/useIntegratedDemandFormHelper';

export const useFdjIntegratedDemandFormHelper = () => {
  const { value } = useFdjCaseTypeFormValue();
  const isCreationCaseType = value === FdjCaseType.CREATION;
  const { change } = useForm();

  const prefillWithDemand = (demand: FdjRootDemandView) => {
    if (isFdjFullRootDemandView(demand)) {
      change('acquirer', { ...toAcquirerFormValues(demand), eirl: undefined });
      change('candidates', demand.candidates.map(toCandidateFormValues));
    } else {
      change('acquirer', undefined);
      change('candidates', demand.candidates.map(toLightCandidateFormValues));
    }
  };

  return useIntegratedDemandFormHelper({ isCreationCaseType, prefillWithDemand });
};
