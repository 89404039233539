import { FdjDemand } from '../../../fdjDemandTypes';
import { CurrentFdjUserDetails } from '../../../../security/types';
import { UserCompanyRestrictionToMarkCommentsAsReadFn } from '@eogile/agrements-common/src/demand/components/comments/hooks/internal/commentsHookType';

/**
 * Only "agency" users belonging to the same agency can mark comments as read.
 */
export const checkCompanyUserRestrictionToMarkAsRead: UserCompanyRestrictionToMarkCommentsAsReadFn = (
  userDetails,
  demand,
) => {
  const fdjDemand = demand as FdjDemand;
  const fdjUserDetails = userDetails as CurrentFdjUserDetails;
  return fdjDemand.agency.code === fdjUserDetails.agency?.code;
};
