import { FdjAgency } from '../common-types/direction/fdjDirectionTypes';
import { Acquirer, Candidate, Demand, IntegratedDemand, Outlet } from '@eogile/agrements-common/src/demand/demandTypes';
import { GedSignedVerdictFileState } from '../ged/gedTypes';

export type FdjDemand = Demand & FdjDemandCommon & (FdjLightDemandSpecific | FdjFullDemandSpecific);
export type FdjLightDemand = Demand & FdjDemandCommon & FdjLightDemandSpecific;
export type FdjFullDemand = Demand & FdjDemandCommon & FdjFullDemandSpecific;

type FdjDemandCommon = Readonly<{
  agency: FdjAgency;
  caseType: FdjCaseType;
  agreementType?: FdjAgreementType;
  ongoingPmuDemand: boolean;
  previousChronoNumber?: string;
  rootDemand: boolean;
  integratedDemand: IntegratedDemand;
  gedSignedVerdictFileState?: GedSignedVerdictFileState;
}>;

type FdjLightDemandSpecific = Readonly<{
  receivedGrantedVerdictInPast12Months: true;
  outlet: FdjLightOutlet;
  candidates: FdjLightCandidate[];
}>;

type FdjFullDemandSpecific = Readonly<{
  receivedGrantedVerdictInPast12Months: false;
  outlet: FdjFullOutlet;
  candidates: FdjFullCandidate[];
  acquirer: Acquirer;
}>;

export type FdjLightOutlet = Pick<Outlet, 'name' | 'code' | 'address'>;
export type FdjFullOutlet = Outlet;

export type FdjFullCandidate = Candidate<FdjCandidateType>;
export type FdjLightCandidate = Pick<
  FdjFullCandidate,
  'civility' | 'lastName' | 'firstNames' | 'birthCity' | 'birthDate'
>;

export enum FdjCaseType {
  CREATION = 'CREATION',
  TRANSFER = 'TRANSFER',
  AGREEMENT_ADDITION = 'AGREEMENT_ADDITION',
  CAPITAL_MODIFICATION = 'CAPITAL_MODIFICATION',
  GOVERNING_BODY_MODIFICATION = 'GOVERNING_BODY_MODIFICATION',
  CAPITAL_AND_GOVERNING_BODY_MODIFICATION = 'CAPITAL_AND_GOVERNING_BODY_MODIFICATION',
  MOVE = 'MOVE',
}

export enum FdjAgreementType {
  LOTTERY = 'LOTTERY',
  SPORTS_BETTING = 'SPORTS_BETTING',
  LOTTERY_AND_SPORTS_BETTING = 'LOTTERY_AND_SPORTS_BETTING',
}

export enum FdjCandidateType {
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
  PRESIDENT = 'PRESIDENT',
  MANAGER = 'MANAGER',
  CO_MANAGER = 'CO_MANAGER',
  ASSOCIATE = 'ASSOCIATE',
}

export enum FdjAttachmentType {
  PieceIdentite = 'PieceIdentite',
  AvisImposition = 'AvisImposition',
  LivretFamille = 'LivretFamille',
  ActeAchat = 'ActeAchat',
  Adjudication = 'Adjudication',
  ContratLocationGerance = 'ContratLocationGerance',
  ConventionOccupation = 'ConventionOccupation',
  AccordPret = 'AccordPret',
  JustificationApports = 'JustificationApports',
  PlanFinancement = 'PlanFinancement',
  ExtraitK = 'ExtraitK', // not used anymore
  ExtraitKbis = 'ExtraitKbis',
  StatutsSociete = 'StatutsSociete',
  LiasseFiscale = 'LiasseFiscale',
  CessionParts = 'CessionParts',
  JustificationFondsFinancementLocationGerance = 'JustificationFondsFinancementLocationGerance',
  ContratAchatExclusifBoissons = 'ContratAchatExclusifBoissons',
  AttestationNonChangementOrganesDirection = 'AttestationNonChangementOrganesDirection', // not used anymore
  NonChangementOrganesDirection = 'NonChangementOrganesDirection',
  AttestationSurHonneur = 'AttestationSurHonneur',
  AttestationEnqueteSccj = 'AttestationEnqueteSccj',
  PlanCadastral = 'PlanCadastral',
  AutresPieces = 'AutresPieces',
}

export type FdjRootDemandView = FdjFullRootDemandView | FdjLightRootDemandView;

export type FdjFullRootDemandView = Readonly<{
  chronoNumber: string;
  receivedGrantedVerdictInPast12Months: false;
  candidates: readonly FdjFullCandidate[];
  acquirer: Acquirer;
}>;

export type FdjLightRootDemandView = Readonly<{
  chronoNumber: string;
  receivedGrantedVerdictInPast12Months: true;
  candidates: readonly FdjLightCandidate[];
  acquirer: null;
}>;

export function isFdjFullRootDemandView(rootDemand: FdjRootDemandView): rootDemand is FdjFullRootDemandView {
  return !rootDemand.receivedGrantedVerdictInPast12Months;
}
