import React from 'react';
import { FdjDemand } from '../../../fdjDemandTypes';
import { CurrentFdjApplicationUserDetails } from '../../../../security/types';
import { checkCompanyUserRestrictionToMarkAsRead } from '../utils/fdjCommentUtils';
import DemandCommentsSection from '@eogile/agrements-common/src/demand/components/comments/section/DemandCommentsSection';

type FdjDemandCommentsSectionProps = {
  demand: FdjDemand;
  currentUserDetails: CurrentFdjApplicationUserDetails;
  onDemandRefresh: (demand: FdjDemand) => void;
};

function FdjDemandCommentsSection({ demand, currentUserDetails, onDemandRefresh }: FdjDemandCommentsSectionProps) {
  return (
    <DemandCommentsSection<FdjDemand>
      demand={demand}
      currentUserDetails={currentUserDetails}
      onDemandRefresh={onDemandRefresh}
      checkCompanyUserRestrictionToMarkAsRead={checkCompanyUserRestrictionToMarkAsRead}
    />
  );
}

export default React.memo(FdjDemandCommentsSection);
